import {
  AspectRatio,
  Avatar,
  Box,
  chakra,
  CloseButton,
  Heading,
  HStack,
  Image,
  Tag,
  TagLabel,
  useBreakpointValue,
  Text,
  VStack,
  IconButton,
} from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaPhoneAlt } from 'react-icons/fa'
import moment from 'moment'


const MotionBox = motion(Box)

export default function DriverDetailsHome({ isOpen, onClose, driver }) {
  const { t } = useTranslation()

  const margin = useBreakpointValue({ base: '0', lg: '8' })
  const width = useBreakpointValue({ base: '100vw', lg: '25vw' })
  const borderRadius = useBreakpointValue({ base: 'lg', md: 'lg' })
  const height = useBreakpointValue({ base: '20vh' })

  const base = {
    initial: { y: 100, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    exit: { y: 100, opacity: 0 },
    transition: {
      duration: 0.5,
      ease: [0.6, -0.05, 0.01, 0.9],
    },
  }

  const lg = {
    initial: { x: -250, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: -250, opacity: 0 },
    transition: {
      duration: 0.5,
      ease: [0.6, -0.05, 0.01, 0.9],
    },
  }

  const { initial, animate, exit, transition } = useBreakpointValue({
    base: base,
    lg: lg,
  })

  return (
    <AnimatePresence exitBeforeEnter>
      {isOpen && (
        <MotionBox
          margin={margin}
          minW={width}
          minH={height}
          bgColor='white'
          // bgColor='whiteAlpha.500'
          // backdropFilter='blur(6px)'
          position='absolute'
          bottom={{ base: 0, lg: 4 }}
          left={{ base: 0, lg: 8 }}
          borderRadius={borderRadius}
          padding={{ base: 1, md: 2 }}
          initial={initial}
          animate={animate}
          exit={exit}
          transition={transition}
          spacing={8}
        >
          <HStack alignItems='flex-start' justifyContent='space-between'>
            <VStack alignItems='flex-start'>
              <Heading size='md'>{driver.fullName}</Heading>
              <Heading size='sm'>
                {t('driverDetailsHome.registration_number')}{' '}
                {driver.vehicle.registrationNumber}
              </Heading>
              <Heading size='sm'>
                {driver.vehicle.brand || ''}
                <chakra.span ml={1} fontSize='sm'>
                  ({driver.vehicle.year || ''})
                </chakra.span>
              </Heading>

              {driver.speaksEnglish && (
                <Text>{t('driverDetailsHome.englishSpoken')}</Text>
              )}
              {driver.acceptCreditCard && (
                <Text>{t('driverDetailsHome.acceptCreditCard')}</Text>
                // <Tag variant='subtle' colorScheme='green' mr={2}>
                //   {/* <TagLabel>Credit Card Accepted</TagLabel> */}
                //   <TagLabel>Tarjeta de crédito aceptada</TagLabel>
                // </Tag>
              )}
              <HStack>
                <IconButton
                  aria-label='call-driver'
                  colorScheme='green'
                  as='a'
                  href={`tel:${driver.phoneNumber}`}
                  icon={<FaPhoneAlt />}
                  size='sm'
                />
                <Text>{driver.phoneNumber}</Text>
                {
                  !driver.isOnline && driver.lastSeen ?
                    <Text style={{ marginLeft: '12px' }} fontSize='xs'>{t('driverDetailsHome.lastSeen')}  <br /> <strong>{moment(driver.lastSeen).local().format('YYYY-MM-DD HH:mm')}</strong></Text>
                    : null
                }
              </HStack>

              {driver.premiumAdText && <HStack >
                <Text
                  fontSize='md'
                  style={{
                    border: '3px solid #5a5a5a',
                    padding: '5px',
                    borderRadius: '4px',
                    wordBreak: 'break-word',
                    maxWidth: '300px',
                    marginTop: '6px',
                  }}
                  dangerouslySetInnerHTML={{ __html: driver.premiumAdText.replace(/(?:\r\n|\r|\n)/g, '<br>') }}
                ></Text>
              </HStack>}

            </VStack>
            <Avatar
              size='xl'
              name={driver.fullName}
              src={driver.images.profile.url}
            />
          </HStack>
          <AspectRatio ratio={16 / 9} minW={40} my={2}>
            <Image
              borderRadius='md'
              aria-label={driver.vehicle.registrationNumber}
              objectFit='fill'
              src={driver.vehicle.images.vehicle.url}
            />
          </AspectRatio>
          <HStack justifyContent='flex-end' mt={2}>
            <CloseButton onClick={onClose} alignSelf='flex-end' />
          </HStack>
        </MotionBox>
      )}
    </AnimatePresence>
  )
}
