import {
    Box,
    Button,
    ButtonGroup,
    Center,
    chakra,
    Container,
    FormControl,
    FormHelperText,
    FormLabel,
    Heading,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    Spinner,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react'
import { Autocomplete } from '@react-google-maps/api'
import { Form, Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useGeolocation } from '../contexts/GeolocationContext'
import Geocode from 'react-geocode'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useBooking } from '../contexts/BookingContext'

import { isValidPhoneNumber } from 'libphonenumber-js'
import { useLocalStorage } from '@mantine/hooks'
import { useTranslation } from 'react-i18next'
import LanguageSelector from '../components/LanguageSelector'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
Geocode.enableDebug()

function BookingLaterPage() {
    const { t } = useTranslation()
    const { setBooking } = useBooking()
    const navigate = useNavigate()
    const toast = useToast()
    const { position } = useGeolocation()
    const nameRef = useRef(null)
    const originRef = useRef(null)
    const destinationRef = useRef(null)

    const [origin, setOrigin] = useState({ location: '', address: '' })
    const [destination, setDestination] = useState({ location: '', address: '' })
    const [phoneNumber, setPhoneNumber] = useState('')
    const [localPhoneNumber, setLocalPhoneNumber] = useLocalStorage({
        key: 'x-phone-number',
        defaultValue: '',
    })

    const [localName, setLocalName] = useLocalStorage({
        key: 'x-name',
        defaultValue: '',
    })


    const [startDate, setStartDate] = useState(new Date());
    const [passengerQuantity, setPassengerQuantity] = useState('');
    const [bagQuantity, setBagQuantity] = useState('');
    const [observations, setObservations] = useState('');


    useEffect(() => {
        nameRef.current.value = localName
    }, [localName])

    useEffect(() => {
        setPhoneNumber(localPhoneNumber)
        console.log("hello------>testing")
    }, [localPhoneNumber])

    useEffect(() => {
        /** @type GeolocationPosition */
        if (position) {
            Geocode.fromLatLng(
                position.coords.latitude,
                position.coords.longitude
            ).then(
                response => {
                    const address = response.results[0]?.formatted_address
                    console.log(response.results[0])
                    if (originRef.current.value === '') {
                        originRef.current.value = address
                        setOrigin({
                            location: response.results[0]?.geometry.location,
                            address: address,
                        })
                    }
                },
                error => {
                    console.error(error)
                }
            )
        }
    }, [position])

    useEffect(() => {
        console.log('origin', origin)
        console.log('destination', destination)
    }, [origin, destination])

    const handleOrigin = () => {
        Geocode.fromAddress(originRef.current.value).then(
            response => {
                const address = response.results[0]?.formatted_address
                setOrigin({
                    location: response.results[0]?.geometry.location,
                    address: address,
                })
            },
            error => {
                console.error(error)
            }
        )
    }

    const handleDestination = () => {
        Geocode.fromAddress(destinationRef.current.value).then(
            response => {
                const address = response.results[0]?.formatted_address
                setDestination({
                    location: response.results[0]?.geometry.location,
                    address: address,
                })
            },
            error => {
                console.error(error)
            }
        )
    }

    const showSpinner = () => {
        if (originRef.current != null && originRef.current.value === '') {
            return (
                <InputRightElement>
                    <Spinner size='sm' />
                </InputRightElement>
            )
        }
        return null
    }

    const onSubmit = e => {
        e.preventDefault()
        if (nameRef.current.value === '') {
            showError(t('page2.nameIsRequired'))
            return
        }
        if (origin.address === '' || origin.location === '') {
            showError(t('page2.originIsRequired'))
            return
        }
        if (destination.address === '' || destination.location === '') {
            showError(t('page2.destinationIsRequired'))
            return
        }
        if (phoneNumber === '') {
            showError(t('page2.phoneIsRequired'))
            return
        }
        // const number = `${dialCode}${phoneNumber}`
        // Validate Number
        // console.log('number', number, dialCode, phoneNumber)
        console.log(
            'isValidPhoneNumber',
            isValidPhoneNumber(`+${phoneNumber}`),
            phoneNumber
        )
        if (!isValidPhoneNumber(`+${phoneNumber}`)) {
            showError(t('page2.phoneInvalid'))
            return
        }

        const booking = {
            name: nameRef.current.value,
            phoneNumber: `+${phoneNumber}`,
            origin,
            destination,
            startDate,
            passengerQuantity,
            bagQuantity,
            observations,
            isBookLater: window.location.pathname.split("/")[1] === 'booking-later' ?? false
        }

        /* 
        Setting values for local storage to be remembered for next booking
        */
        setLocalName(nameRef.current.value)
        setLocalPhoneNumber(phoneNumber)

        console.log('Final value', booking)
        setBooking(booking)
        navigate('/confirm-booking')

        console.log("passengerQuantity", passengerQuantity)
        console.log("startDate", startDate)
    }

    const showError = message => {
        toast({
            description: message,
            status: 'error',
            duration: 2000,
            isClosable: true,
        })
    }

    const handleDateChange = (date) => {
        console.log('Selected Date:', date); // Log the selected date to the console
        setStartDate(date);
    };
    return (
        <VStack minH='100vh' flexDirection='column' justifyContent='space-between'>
            <Box alignSelf='flex-end' m={4}>
                <LanguageSelector />
            </Box>
            <VStack
                p={8}
                shadow={{ base: 'none', md: 'base' }}
                borderRadius='xl'
                maxW='container.md'
                alignItems='stretch'
                spacing={8}
            >
                <HStack justifyContent='space-between'>
                    {/* <Heading>{t('page2.register')}</Heading> */}
                    <Button
                        as='a'
                        href={t('helpUrl')}
                        colorScheme='primary'
                        target='_blank'
                    >
                        {t('page2.help')}
                    </Button>
                </HStack>
                {/* <Text>{t('page2.message')}</Text> */}
                <chakra.form onSubmit={onSubmit}>
                    <VStack alignItems='stretch' spacing={4}>
                        <FormControl>
                            <FormLabel htmlFor='name'>{t('page2.name')}</FormLabel>
                            <Input id='name' type='text' ref={nameRef} isRequired />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor='phoneNumber'>
                                {t('page2.phoneNumber')}
                            </FormLabel>
                            {/* <Input id='phoneNumber' type='text' ref={phoneNumberRef} /> */}
                            <PhoneInput
                                country={'us'}
                                value={phoneNumber}
                                onChange={(value, country, e, formattedValue) => {
                                    console.log(value, country)
                                    setPhoneNumber(value)
                                }}
                                countryCodeEditable={false}
                                enableSearch
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                }}
                                inputStyle={{
                                    width: '100%',
                                    borderColor: '#e4e4e7',
                                    borderRadius: '6px',
                                }}
                                buttonStyle={{
                                    backgroundColor: 'white',
                                    borderColor: '#e4e4e7',
                                }}
                            />
                            <FormHelperText>{t('page2.phoneHelperText')}</FormHelperText>
                        </FormControl>
                        <Autocomplete onPlaceChanged={handleOrigin}>
                            <FormControl>
                                <FormLabel htmlFor='origin'>{t('page2.origin')}</FormLabel>
                                <InputGroup>
                                    <Input id='origin' type='text' ref={originRef} isRequired />
                                    <InputRightElement
                                        display={origin.address === '' ? 'inline-flex' : 'none'}
                                    >
                                        <Spinner size='sm' />
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                        </Autocomplete>
                        <Autocomplete onPlaceChanged={handleDestination}>
                            <FormControl>
                                <FormLabel htmlFor='destination'>
                                    {t('page2.destination')}
                                </FormLabel>
                                <Input
                                    id='destination'
                                    type='text'
                                    ref={destinationRef}
                                    isRequired
                                    placeholder={t('page2.EnteraLocation')}
                                />
                            </FormControl>
                        </Autocomplete>
                        <FormControl>
                            <FormLabel htmlFor='selectedDate'>{t('page2.SelectDate')}</FormLabel>
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} minDate={new Date()} showTimeSelect timeFormat='HH:mm' timeIntervals={15} dateFormat='dd/MM/yyyy HH:mm' className='chakra-input css-38ybon pac-target-input' />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor='name'>{t('page2.QuantityOfPassengers')}</FormLabel>
                            <Input id='quantitypassengers' type='number' placeholder={t('page2.QuantityOfPassengers')} value={passengerQuantity} onChange={(e) => setPassengerQuantity(e.target.value)} isRequired />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor='name'>{t('page2.QuantityOfBags')}</FormLabel>
                            <Input id='quantitybags' type='number' placeholder={t('page2.QuantityOfBags')} value={bagQuantity} onChange={(e) => setBagQuantity(e.target.value)} isRequired />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor='name'>{t('page2.EntryForObservations')}</FormLabel>
                            <Input id='entryobservations' type='text' placeholder={t('page2.EntryForObservations')} value={observations} onChange={(e) => setObservations(e.target.value)} />
                        </FormControl>
                        <Button colorScheme='primary' type='submit' alignSelf='self-start'>
                            {t('page2.calculateRoute')}
                        </Button>
                    </VStack>
                </chakra.form>
            </VStack>
            <Text fontSize='sm' py={8}>
                {t('footer.copyright')} © {new Date().getFullYear()} Phone2Web Spa .{' '}
                {t('footer.message')}
            </Text>
        </VStack>
    )
}

export default BookingLaterPage